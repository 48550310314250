import { useState } from "react";
import "./App.scss";
import Logo from "./img/logo.png";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { getIccdsData, getInvoicesData } from "./services/actions";

function App() {
  const [cpf, setCpf] = useState("");
  const [invoicesData, setInvoicesData] = useState([]); 
  const [error, setError] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    setInvoicesData([]);
    setError("Carregando");

    try {
      const userData = await getIccdsData(cpf.replace(/\D/g, ''));
      if(userData.data.length > 0) {
        const iccids = []
        userData.data.forEach((user) => {
          iccids.push(user.iccid)
        });        

        async function processInvoices() {
          const invoices = []
          for (const iccid of iccids) {
            const invoicesData = await getInvoicesData(iccid)
            if (invoicesData.data.length > 0) {
              invoicesData.data.forEach((invoice) => {
                if (!invoice.paid) invoices.push(invoice)
              })
            }
          }
          if(invoices.length < 1){
            setError("Não há faturas não pagas no momento para este CPF.")
          }
          setInvoicesData(invoices)
        }
        
        processInvoices();

      }else{
        setError("CPF ou CPNJ não encontrado")
        return;
      }
    } catch (error) {
      setError("Erro ao buscar faturas, tente novamente.")
    }
  }

  return (
    <main className="App">
      <section className="main-content">
        <div className="header">
          <img src={Logo} alt="logo" />
          <h1>Buscador de faturas</h1>
        </div>
        <form className="search" onSubmit={handleSubmit}>
          <CpfCnpj
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
            placeholder="Digite seu CPF ou CNPJ"
          />
          <button type="submit">Buscar</button>
        </form>

        {invoicesData.length > 0 ? <table className="invoice-table">
          <thead>
            <tr>
              <th>Data de Geração</th>
              <th>Valor</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {invoicesData.map((invoice) => (
              <tr key={invoice.invoicenumber}>
                <td>{invoice.created}</td>
                <td>{invoice.valuetopup}</td>
                <td><a target="_blank" rel="noreferrer" href={invoice.invoiceurl}>Pagar</a></td>
              </tr>
            ))}
          </tbody>  
        </table>
        : <p>{error}</p>}
      </section>
    </main>
  );
}

export default App;
